import request from '@/utils/request'

// 问卷调查 未填  代办处/经销商
export function getUnfilled(query) {
    return request({
        url: '/topics/getUnfilled'
        , method: 'get'
        , params: query
    })
}

// 问卷调查 查看  代办处/经销商
export function viewById(query) {
    return request({
        url: '/topics/viewById'
        , method: 'get'
        , params: query
    })
}

// 问卷调查-答题  代办处/经销商
export function topicsAnswer(data) {
    return request({
        url: '/topics/answer'
        , method: 'post'
        , data
    })
}

// 问卷调查-答题App  代办处/经销商
export function topicsAnswerApp(data) {
    return request({
        url: '/topics/answerApp'
        , method: 'post'
        , data
    })
}
