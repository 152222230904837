<template>
  <div style="padding: 10px 10px 0">
    <div v-if="!ifView">
      <!-- 暂无问卷 -->
      <p v-if="NoQuestionnaire">{{ $t('None_questionnaire') }}</p>
      <div v-else-if="!NoQuestionnaire">
        <div class="each-file" v-for="item in list" :key="item.id" @click="getInfoBtn(item.id)">
          <p class="each-file-p">{{ item.topName }}</p>
      </div>
    </div>
    </div>
    <div v-if="ifView" style="text-align: left">
      <el-form>
        <el-form-item>
          <div v-for="(item, index) in viewObj.surveyTittleDTOs" :key="index" class="questionnaire-div">
            <div class="questionnaire-title">
              <span style="font-size:16px ;color:#000000; font-weight: 600">{{ index+1 }}.{{ item.tittleName }}&nbsp;</span>
              <span style="font-size:14px ;color:#909399">({{ $t(typeMap[item.type]) }})</span>
            </div>
            <!-- 单选 -->
            <div v-if="item.type === 0" class="questionnaire-subject">
              <el-radio-group v-model="item.surveyKeyDTOs" style="padding-top: 12px">
                <div v-for="(value, i) in item.surveyKeys" :key="i">
                  <el-radio style="margin-bottom: 10px;" :label="value.id">{{ (i+1)+'、'+value.keyName }}</el-radio>
                </div>
              </el-radio-group>
            </div>
            <!-- 多选 -->
            <div v-else-if="item.type === 1" class="questionnaire-subject">
              <el-checkbox-group v-model="item.surveyKeyDTOs">
                <div v-for="(value, i) in item.surveyKeys" :key="i">
                  <el-checkbox :label="value.id ">{{ (i+1)+'、'+value.keyName }}</el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </div>
        </el-form-item>
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <!--        返回-->
          <el-button :loading="btnLoading" icon="el-icon-back" size="medium" type="warning" @click="ifView = false">{{ $t('operation.button.return') }}</el-button>
          <!--        提交-->
          <el-button :loading="btnLoading" size="medium" type="primary" @click="saveBtn">{{ this.$t('button.submit') }}</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  import { getUnfilled, viewById, topicsAnswer } from '@/api/survey/topics'
  export default {
    name: 'QuestionnaireSurvey',
    data() {
      return {
        NoQuestionnaire: true,
        btnLoading: false,
        ifView: false,
        typeMap: ['Single_choice', 'Multiple_choice'],
        viewObj: {},
        list: []
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        getUnfilled().then(res => {
          this.NoQuestionnaire = res.data.length === 0
          this.list = res.data
        }).catch(err => {
          console.error(err)
        })
      },
      getInfoBtn(id) {
        let loading = this.$loading({
          lock: true, //lock的修改符--默认是false
          text: this.$t('app.loading') + '...', //显示在加载图标下方的加载文案
          background: "rgba(0,0,0,0.4)", //遮罩层颜色
          spinner: "el-icon-loading", //自定义加载图标类名
        });
        viewById({ 'id': id}).then(res => {
          this.ifView = true
          this.viewObj = res.data
          for (let a = 0; a < this.viewObj.surveyTittleDTOs.length; a++) {
            this.viewObj.surveyTittleDTOs[a].surveyKeyDTOs = this.viewObj.surveyTittleDTOs[a].type === 0 ? '' : []
          }
          loading.close()
        }).catch((err) => {
          loading.close()
          console.log(err)
        })
      },
      saveBtn() {
        this.btnLoading = true
        // 判断是否全部填写
        const { surveyTittleDTOs } = this.viewObj
        for (let a = 0; a < surveyTittleDTOs.length; a++) {
          if (surveyTittleDTOs[a].surveyKeyDTOs.toString() <= 0) {
            this.btnLoading = false
            return  this.$message.info(`第${a + 1 }道未作答`)
          }
        }
        // 存放要提交的值
        let newINfoList = []
        for (let a = 0; a < surveyTittleDTOs.length; a++) {
          if (surveyTittleDTOs[a].type === 0) {
            let dan = {
                topId : surveyTittleDTOs[a].topId,
                tittleId : surveyTittleDTOs[a].id,
                keyId: surveyTittleDTOs[a].surveyKeyDTOs
              }
            newINfoList.push(dan)
          } else if (surveyTittleDTOs[a].type === 1) {
            const duoList = surveyTittleDTOs[a].surveyKeyDTOs
            for (let b = 0; b < duoList.length; b++) {
              let duo = {
                topId : surveyTittleDTOs[a].topId,
                tittleId : surveyTittleDTOs[a].id,
                keyId: duoList[b]
              }
              newINfoList.push(duo)
            }
          }
        }
        topicsAnswer(newINfoList).then(() => {
          this.ifView = false
          this.btnLoading = false
          this.getList()
        }).catch(err => {
          this.ifView = true
          this.btnLoading = false
          console.log(err, 'err111')
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .each-file {
    word-wrap: break-word;
    border: 0.1px solid #ece0a2;
    border-radius: 1rem;
    background-color: rgba(248, 233, 226, 0.8);
    margin: 10px 0;
    cursor: pointer;
  }
  .each-file-p {
    color: #c48f1b;
    font-weight: bold;
    text-decoration: none;
  }
  .questionnaire-div {
    border: 0.1px solid #e2deca;
    border-radius: 12px;
    background-color: #f1f6fa;
    margin-bottom: 5px;
  }
  .questionnaire-title {
    border-bottom: 0.1px solid #ece0a2;
    background-color: rgba(251, 245, 228, 0.8);
    border-radius: 12px 12px 0 0;
    padding-left: 15px;
  }
  .questionnaire-subject {
    margin-left: 25px;
    color: black;
  }
</style>
